import { useTableProps } from '@blockanalitica/ui';
import { useState, useCallback } from 'react';
import { useSearchParams } from '@/hooks';
import { useSearchParams as useRRSearchParams } from 'react-router';

export default function RateTableFilters(sortingField: string) {
  const { pagination, sorting } = useTableProps({
    pageSize: 15,
    initialSorting: sortingField,
    filtersDebounceTime: 50
  });
  const [, setRouterSearchParams] = useRRSearchParams();

  const updateSearchParams = useCallback(
    (searchParams: Record<string, string | number>) => {
      setRouterSearchParams((prevSearchParams) => {
        const updatedSearchParams = new URLSearchParams(prevSearchParams);
        Object.entries(searchParams).forEach(([key, value]) => {
          if (value !== undefined) {
            updatedSearchParams.set(key, String(value));
          }
        });
        return updatedSearchParams;
      });
    },
    [setRouterSearchParams]
  );

  const [tokensFilteredParam] = useSearchParams('tokens', 'DAI,USDC,USDS');
  const [tokensFiltered, setTokensFiltered] = useState(tokensFilteredParam);

  const [protocolsFilteredParam] = useSearchParams(
    'protocols',
    'sparkprotocol,aaveprotocol,aaveprotocol_lido,COMP,Morpho'
  );
  const [protocolsFiltered, setProtocolsFiltered] = useState(
    protocolsFilteredParam
  );

  const [collateralTokensFilteredParam] = useSearchParams(
    'collateral_tokens',
    'WETH,wstETH'
  );
  const [collateralTokensFiltered, setCollateralTokensFiltered] = useState(
    collateralTokensFilteredParam
  );

  const filterByToken = useCallback(
    (tokens: string[]) => {
      setTokensFiltered(tokens.join(','));
      updateSearchParams({ tokens: tokens.join(',') });
    },

    [updateSearchParams]
  );

  const filterByProtocol = useCallback(
    (protocols: string[]) => {
      setProtocolsFiltered(protocols.join(','));
      updateSearchParams({ protocols: protocols.join(',') });
    },
    [updateSearchParams]
  );

  const filterByCollateralToken = useCallback(
    (collateralTokens: string[]) => {
      setCollateralTokensFiltered(collateralTokens.join(','));
      updateSearchParams({ collateral_tokens: collateralTokens.join(',') });
    },
    [updateSearchParams]
  );

  return {
    pagination,
    sorting,
    tokensFiltered,
    protocolsFiltered,
    collateralTokensFiltered,
    filterByToken,
    filterByProtocol,
    filterByCollateralToken,
    updateSearchParams
  };
}
