import { Page, Tabs } from '@/components';
import { useSearchParams } from '@/hooks';
import KpisAndChart from './BorrowKpisAndChart';
import StablecoinRatesTable from './BorrowRatesTable';
import StablecoinRatesEstimator from './BorrowRatesEstimator';

export default function StablecoinBorrowPage() {
  const [activeTab, setActiveTab] = useSearchParams(
    'tab',
    'stablecoin_pools'
  ) as [
    string,
    (value: string) => void,
    (name: string, value: string) => string
  ];
  const tabs = [
    {
      key: 'stablecoin_pools',
      title: 'Pools',
      content: <StablecoinRatesTable />
    },
    {
      key: 'rate_estimator',
      title: 'Borrow Rate Estimator',
      content: <StablecoinRatesEstimator />
    }
  ];

  return (
    <Page>
      <KpisAndChart />
      <div>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </Page>
  );
}
