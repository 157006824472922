import {
  LineChart,
  ChartSkeleton,
  formatCurrency,
  formatPercentage
} from '@blockanalitica/ui';
import { useCallback, useState, useMemo } from 'react';
import { useApi } from '@/api';
import { useChartPeriod } from '@/hooks';
import type { SimpleChartData } from '@blockanalitica/ui/package/types/src/components/charts/SimpleChart';
import { ChartTimeFilter, Tabs } from '@/components';
import { ChartConfig } from '@blockanalitica/ui/package/types/src/components/charts/Chart';
import { useTheme } from 'styled-components';
import type { TooltipItem } from 'chart.js';

export default function StablecoinStatsChart() {
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState('total_stablecoin_supply');
  const [chartDaysAgo, setChartDaysAgo] = useState('30');

  const [data, error] = useApi<SimpleChartData[]>({
    url: '/overall/history/',
    queryParams: {
      days_ago: chartDaysAgo
    }
  });

  const [, updateChartConfigWithPeriod] = useChartPeriod(chartDaysAgo);

  const valueFormatter = useCallback(
    (value: number | string) => {
      if (activeTab === 'benchmark_rates') {
        return '';
      }
      return formatCurrency(Number(value), { currency: 'USD' });
    },
    [activeTab]
  );

  const yScaleValueFormatter = useCallback(
    (value: number | string) => {
      if (activeTab === 'benchmark_rates') {
        return formatPercentage(Number(value));
      }
      return formatCurrency(Number(value), { currency: 'USD' });
    },
    [activeTab]
  );

  const timeFilter = useMemo(
    () => (
      <ChartTimeFilter activeOption={chartDaysAgo} onChange={setChartDaysAgo} />
    ),
    [chartDaysAgo]
  );

  const datasets = useMemo(() => {
    if (activeTab !== 'benchmark_rates') {
      return [
        {
          xField: 'date',
          yField: activeTab
        }
      ];
    } else {
      return [
        {
          xField: 'date',
          yField: 'supply_rate_benchmark',
          label: 'Supply Benchmark',
          borderColor: theme.colors.success,
          backgroundColor: theme.colors.success
        },
        {
          xField: 'date',
          yField: 'borrow_rate_benchmark',
          label: 'Borrow Benchmark',
          borderColor: theme.colors.error,
          backgroundColor: theme.colors.error
        },
        {
          xField: 'date',
          yField: 'funding_rate_benchmark',
          label: 'Funding Benchmark',
          borderColor: theme.colors.primary,
          backgroundColor: theme.colors.primary
        }
      ];
    }
  }, [activeTab, theme.colors]);

  const defaultConfig: ChartConfig = useMemo(() => {
    if (activeTab === 'benchmark_rates') {
      return {
        options: {
          // @ts-ignore
          fill: false,
          aspectRatio: 2.78,
          plugins: {
            legend: {
              display: true,
              position: 'bottom' as const,
              labels: {
                color: theme.colors.muted,
                boxWidth: 20
              }
            },
            tooltip: {
              itemSort: (a: TooltipItem<'line'>, b: TooltipItem<'line'>) => {
                return b.parsed.y - a.parsed.y;
              }
            }
          }
        }
      };
    }
    return {
      options: {
        aspectRatio: 3
      }
    };
  }, [activeTab, theme.colors.muted]);
  const config = updateChartConfigWithPeriod(defaultConfig);
  const chart = useMemo(
    () => (
      <LineChart
        key={`${activeTab}${chartDaysAgo}`}
        data={data}
        config={config}
        datasets={datasets}
        yScaleValueFormatter={yScaleValueFormatter}
        valueFormatter={valueFormatter}
        timeFilter={timeFilter}
        tooltip={{
          labelFormatter: (value) => `${value}`,
          valueFormatter: (value) => `${formatPercentage(Number(value))}`
        }}
        showKpisAsTooltip={activeTab === 'benchmark_rates' ? true : false}
      />
    ),
    [
      activeTab,
      data,
      config,
      valueFormatter,
      yScaleValueFormatter,
      chartDaysAgo,
      timeFilter,
      datasets
    ]
  );

  const tabs = useMemo(
    () => [
      {
        key: 'total_stablecoin_supply',
        title: 'Total Stablecoin Supply',
        content: chart
      },
      {
        key: 'total_stablecoin_debt',
        title: 'Total Stablecoin Borrow',
        content: chart
      },
      {
        key: 'benchmark_rates',
        title: 'Benchmark Rates',
        content: chart
      }
    ],
    [chart]
  );

  if (error) {
    throw error;
  }

  if (!data) {
    return <ChartSkeleton />;
  }

  return <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />;
}
