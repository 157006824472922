import { ErrorBoundary, Layout } from '@/components';
import HomePage from '@/pages';
import PoolPage from './pages/pool';
import ProtocolPage from './pages/protocol';
import StablecoinSupplyPage from './pages/stablecoinSupply';
import StablecoinBorrowPage from './pages/stablecoinBorrow';
import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    errorElement: (
      <Layout>
        <ErrorBoundary />
      </Layout>
    ),
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/supply',
        element: <StablecoinSupplyPage />
      },
      {
        path: '/borrow',
        element: <StablecoinBorrowPage />
      },
      {
        path: '/:protocol/:poolId',
        element: <PoolPage />
      },
      {
        path: '/:protocol',
        element: <ProtocolPage />
      }
    ]
  }
]);

export default router;
