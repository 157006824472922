import { Page, Tabs } from '@/components';
import { useSearchParams } from '@/hooks';

import SupplyKpisAndChart from './SupplyKpisAndChart';
import StablecoinSupplyRatesTable from './SupplyRatesTable';
import StablecoinSupplyRatesEstimator from './SupplyRatesEstimator';
export default function StablecoinSupplyPage() {
  const [activeTab, setActiveTab] = useSearchParams(
    'tab',
    'stablecoin_pools'
  ) as [
    string,
    (value: string) => void,
    (name: string, value: string) => string
  ];

  const tabs = [
    {
      key: 'stablecoin_pools',
      title: 'Pools',
      content: <StablecoinSupplyRatesTable />
    },
    {
      key: 'rate_estimator',
      title: 'Supply Rate Estimator',
      content: <StablecoinSupplyRatesEstimator />
    }
  ];
  return (
    <Page>
      <SupplyKpisAndChart />
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
    </Page>
  );
}
