import { useApi } from '@/api';
import { ChartSkeleton, Card } from '@blockanalitica/ui';
import { useCallback, useState } from 'react';
import Kpis from './SupplyKpis';
import type { TotalSupply } from './SupplyKpis';
import RatesChart from './SupplyRatesChart';
import type { SupplyRateHistory } from './SupplyRatesChart';
import { Tabs } from '@/components';

export type RateHistoryResponse = {
  history: SupplyRateHistory[];
  total_debt: TotalSupply;
  stablecoins: { key: string; value: string }[];
  collateral_tokens: { key: string; value: string }[];
  protocols: { key: string; value: string }[];
};

export default function SupplyKpisAndChart() {
  const [chartDaysAgo, setChartDaysAgo] = useState('30');
  const [tokensFiltered, setTokensFiltered] = useState<string[]>([
    'DAI',
    'USDC',
    'USDS'
  ]);
  const [protocolsFiltered, setProtocolsFiltered] = useState<string[]>([
    'sparkprotocol',
    'aaveprotocol',
    'aaveprotocol_lido',
    'COMP',
    'Morpho'
  ]);
  const [collateralTokensFiltered, setCollateralTokensFiltered] = useState<
    string[]
  >(['WETH', 'wstETH']);
  const [apyTimeframe, setApyTimeframe] = useState('7d');

  const apyTimeframes = [
    { key: '1d', value: '1d Avg' },
    { key: '7d', value: '7d Avg' },
    { key: '30d', value: '30d Avg' },
    { key: '90d', value: '90d Avg' },
    { key: '365d', value: '365d Avg' }
  ];

  const [data, error] = useApi<RateHistoryResponse>(
    {
      url: '/stablecoin-supply/rate-history/',
      queryParams: {
        days_ago: chartDaysAgo,
        tokens: tokensFiltered.join(','),
        protocols: protocolsFiltered.join(','),
        collateral_tokens: collateralTokensFiltered.join(','),
        apy_timeframe: apyTimeframe
      }
    },
    {
      keepPreviousData: true
    }
  );

  if (error) {
    throw error;
  }
  const filterByTime = useCallback((days: string) => setChartDaysAgo(days), []);

  const filterByToken = useCallback(
    (tokens: string[]) => setTokensFiltered(tokens),
    []
  );

  const filterByProtocol = useCallback(
    (protocols: string[]) => setProtocolsFiltered(protocols),
    []
  );
  const filterByCollateralToken = useCallback(
    (collateralTokens: string[]) =>
      setCollateralTokensFiltered(collateralTokens),
    []
  );
  const changeApyTimeframe = useCallback((timeframe: string | string[]) => {
    setApyTimeframe(timeframe as string);
  }, []);

  if (!data) {
    return <ChartSkeleton />;
  }

  return (
    <>
      <Kpis data={data['total_debt']} />
      <Tabs
        tabs={[
          {
            key: 'supply-rates',
            title: 'Stablecoin Supply Rates',
            content: (
              <Card variant="tabs">
                <RatesChart
                  data={data['history']}
                  callbacks={{
                    filterByTime,
                    filterByToken,
                    filterByProtocol,
                    filterByCollateralToken,
                    changeApyTimeframe
                  }}
                  filters={{
                    timeFilter: chartDaysAgo,
                    tokensFiltered,
                    protocolsFiltered,
                    collateralTokensFiltered,
                    apyTimeframe,
                    tokensOptions: data['stablecoins'],
                    protocolsOptions: data['protocols'],
                    collateralTokensOptions: data['collateral_tokens'],
                    apyTimeframeOptions: apyTimeframes
                  }}
                />
              </Card>
            )
          }
        ]}
        activeTab="supply-rates"
        setActiveTab={() => null}
      />
    </>
  );
}
