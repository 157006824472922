import { Page, PageSkeleton } from '@/components';
import { useApi } from '@/api';
import {
  Flex,
  Text,
  Box,
  useIsMobile,
  formatCurrency
} from '@blockanalitica/ui';
import StablecoinStatsChart from './StablecoinStatsChart';
import { useTheme } from 'styled-components';
import StablecoinRatesHomepageForm from './StablecoinRatesHomepageForm';
type HomePageResponse = {
  total_stablecoin_supply: string;
  total_stablecoin_debt: string;
  total_stablecoin_supply_change: string | null;
  total_stablecoin_debt_change: string | null;
  total_stablecoin_supply_change_percentage: string | null;
  total_stablecoin_debt_change_percentage: string | null;
};

export default function HomePage() {
  const isMobile = useIsMobile();

  const theme = useTheme();

  const [data, error] = useApi<HomePageResponse>({
    url: '/overall/'
  });

  if (error) {
    throw error;
  }
  if (!data) {
    return <PageSkeleton />;
  }

  const stablecoinDataTitle = (
    <Text fontSize="5" display="inline-flex" color={theme.colors.primary}>
      Stablecoin Data
    </Text>
  );

  const rateEstimationsTitle = (
    <Text fontSize="5" display="inline-flex" color={theme.colors.primary}>
      Rate Estimations
    </Text>
  );

  const stablecoinSupply = (
    <Text
      fontSize={['2', '3']}
      display="inline-flex"
      color={theme.colors.primary}>
      {formatCurrency(Number(data.total_stablecoin_supply), {
        currency: 'USD'
      })}
    </Text>
  );

  const stablecoinDebt = (
    <Text
      fontSize={['2', '3']}
      display="inline-flex"
      color={theme.colors.primary}>
      {formatCurrency(Number(data.total_stablecoin_debt), {
        currency: 'USD'
      })}
    </Text>
  );
  return (
    <Page>
      <Flex flexDirection="column" gap="3xl" marginTop="3xl">
        <Box marginBottom={isMobile ? '3xl' : '0'} textAlign="center">
          <Text fontSize="5" marginBottom="l">
            {stablecoinDataTitle} from DeFi Lending Protocols
          </Text>
          <Text
            fontSize={['2', '3']}
            color={theme.colors.muted}
            style={{ lineHeight: isMobile ? '2rem' : '3rem' }} // Adjust line height for readability
          >
            Track stablecoin supply and borrow rates across top DeFi lending
            protocols. Currently supporting {stablecoinSupply} of stablecoins
            supplied and {stablecoinDebt} of stablecoins borrowed on Sparklend,
            Sky, Morpho, AaveV3, and CompoundV3.
          </Text>
        </Box>
        <StablecoinStatsChart />
        <Box textAlign="center">
          <Text fontSize="5" marginBottom="l">
            {rateEstimationsTitle} for Stablecoin Suppliers and Borrowers
          </Text>
          <Text
            fontSize={['2', '3']}
            color={theme.colors.muted}
            style={{ lineHeight: isMobile ? '2rem' : '3rem' }}>
            Stablecoin suppliers and borrowers can estimate how supplying or
            borrowing will impact rates. Select either supply or borrow and
            enter any amount to see the potential rate change.
          </Text>
        </Box>
        <Box alignSelf="center">
          <StablecoinRatesHomepageForm />
        </Box>
      </Flex>
    </Page>
  );
}
