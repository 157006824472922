import {
  Box,
  CryptoIcon,
  Dropdown,
  DropdownOption,
  Flex
} from '@blockanalitica/ui';

interface DropdownAssetsFilterProps {
  callback?: (value: string[]) => void;
  options: { key: string; value: string }[];
  initialValue?: string[] | string;
  withIcon?: boolean;
  titleFormat?: (count: number) => string; // Custom title format for multi-select
}

export default function DropdownAssetsFilter({
  callback,
  options,
  initialValue,
  withIcon = true,
  ...rest
}: DropdownAssetsFilterProps) {
  // convert initial value to array if its comma separated string
  if (typeof initialValue === 'string') {
    initialValue = initialValue.split
      ? initialValue.split(',').map((v) => v.trim())
      : [initialValue];
  }

  return (
    <Dropdown
      multi
      onChange={callback as (value: string | string[]) => void}
      iconSize="-2"
      initialValue={initialValue}
      options={{
        variants: {
          dropdownHeader: 'secondary'
        }
      }}
      {...rest}>
      {options.map(({ key, value }) => (
        <DropdownOption key={key} value={key} variant="secondary">
          <Flex gap="3xs" alignItems="center">
            {withIcon ? (
              <CryptoIcon
                name={key}
                size="1"
                address={
                  key === 'SKY'
                    ? '0x72ac6a36de2f72bd39e9c782e9db0dcc41febfe2'
                    : undefined
                }
              />
            ) : null}

            <Box>{value}</Box>
          </Flex>
        </DropdownOption>
      ))}
    </Dropdown>
  );
}
