import {
  Box,
  Flex,
  HomeIcon,
  CoinsStackIcon,
  useIsMobile
} from '@blockanalitica/ui';
import { Outlet, Link } from 'react-router';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import styled from 'styled-components';
import {
  default as PoweredByBA
  // @ts-ignore
} from '@/assets/logos/powered_by_ba.svg?react';
import {
  default as SphereLogo
  // @ts-ignore
} from '@/assets/logos/sphere_with_name.svg?react';
import { ExternalLink } from '@/components';
import Footer from './Footer';
import { useLocation } from 'react-router';

const ContentWrapper = styled(Flex)`
  box-sizing: border-box;
  padding: ${(props) =>
    `${props.theme.space['xs']} ${props.theme.space['s-m']} ${props.theme.space['s-m']}`};
`;

export default function Layout({
  children
}: {
  children?: ReactNode | ReactNode[];
}) {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { pathname } = useLocation();

  const navigationRoutes = [
    {
      title: 'Home',
      linkTo: `/`,
      icon: <HomeIcon size="0" />
    },
    {
      title: 'Supply',
      linkTo: '/supply',
      icon: <CoinsStackIcon size="0" />
    },
    {
      title: 'Borrow',
      linkTo: '/borrow',
      icon: <CoinsStackIcon size="0" />
    }
  ];
  return (
    <Flex height="100%" flexWrap="nowrap" width="100%" gap="0">
      <ContentWrapper
        flexGrow={1}
        flexShrink={1}
        flexBasis={['100%', 'auto']}
        margin={'0 auto'}
        width="100%"
        maxWidth={['100vw', '80rem']}
        flexDirection="column"
        gap="s">
        <Flex justifyContent="space-between" alignItems="center">
          <Box
            // @ts-ignore
            as={Link}
            to="/">
            <SphereLogo height={50} />
          </Box>
          {/* Add the navigation routes here */}
          {!isMobile ? (
            <Flex
              as="nav"
              gap="m"
              alignItems="center"
              border="1px solid"
              borderRadius="3"
              borderColor={theme.colors.primary}
              padding="xs"
              paddingLeft="xl"
              paddingRight="xl">
              {navigationRoutes.map((route) => (
                <Box
                  key={route.title}
                  // @ts-ignore
                  as={Link}
                  to={route.linkTo}
                  display="flex"
                  alignItems="center"
                  color={
                    pathname === route.linkTo ? theme.colors.primary : 'inherit'
                  }>
                  <span style={{ marginLeft: '0.5rem' }}>{route.title}</span>
                </Box>
              ))}
            </Flex>
          ) : null}
          <ExternalLink color="white" href="https://blockanalitica.com">
            <PoweredByBA height={45} />
          </ExternalLink>
        </Flex>
        {isMobile ? (
          <Flex
            as="nav"
            gap="3xl"
            alignItems="center"
            border="1px solid"
            borderRadius="3"
            justifyContent="center"
            borderColor={theme.colors.primary}
            padding="xs">
            {navigationRoutes.map((route) => (
              <Box
                key={route.title}
                // @ts-ignore
                as={Link}
                to={route.linkTo}
                display="flex"
                alignItems="center"
                color={
                  pathname.startsWith(route.linkTo)
                    ? theme.colors.primary
                    : 'inherit'
                }>
                <span style={{ marginLeft: '0.5rem' }}>{route.title}</span>
              </Box>
            ))}
          </Flex>
        ) : null}
        <main>{children ? children : <Outlet />}</main>

        <Footer />
      </ContentWrapper>
    </Flex>
  );
}
